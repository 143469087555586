<template>
    <div>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-card-code title=" الخدمات الخاصة ب العائلة">
            <vue-good-table
        :columns="columns"
        :rows="rows"
       
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
            <span class="text-nowrap text-center">{{ props.row.proposal_service.name }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'note'">
          
          <span>  {{ props.row.notes }} </span>
          
        </span>

          
         
        </template>
      </vue-good-table>
           
          </b-card-code>
        </b-form>
      </validation-observer>

      <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="إضافة خدمة جديدة للعائلة">
          <title>إضافة خدمة جديدة للعائلة</title>

          <b-row>
       

            <b-col md="6" xl="3">
              <validation-provider #default="{ errors }" name="service" rules="required">
                <b-form-group
                  label-for="service"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة</label>
                  <v-select
                    v-model="Mainservice"
                    :reduce="(val) => val.value"
                    :options="optionService"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="cardNumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة الفرعية </label>
                  <v-select
                    v-model="proposal_service_id"
                    :reduce="(val) => val.value"
                    :options="optionSubServices"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col md="4" xl="4">
              <b-form-group label-for="delimiter">
                <validation-provider #default="{ errors }" name="noteservices" rules="">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-default"
                    :state="errors.length > 0 ? false : null"
                    v-model="notes"
                    placeholder="الملاحظات"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <br />
            <br />
          </b-row>
          <b-row>
          <b-col md="12" xl="4"></b-col>
          
          <b-col
            class="border-Primary d-flex justify-content-between flex-column mt-2 mt-sm-0 "
            md="3"
            xl="3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="addservice"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle"> إضافة الخدمة </span>
            </b-button>
          </b-col>
          <br />
          <br />
        </b-row>
          <br />
          <br />
          <br />

          <br />
          <br />
        </b-card-code>
        
        <br />
        <br />
      </b-form>
    </validation-observer>

    
      <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="$router.go(-1)"
    >
      <span class="align-middle">رجوع</span>
    </b-button>
    </div>
  </template>
  
  <script>
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import Cleave from "vue-cleave-component";

  import {
    BFormDatepicker,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
    BButton,
    BImg,
    BFormInvalidFeedback,
   
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  export default {
    components: {
      BCardCode,
  
      BForm,
      VueGoodTable,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
  
      BImg,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
  
      BRow,
      vSelect,
      BCol,
      ToastificationContent,
    },
  
    data() {
      
    return {
    role:'',
    familyservice: [],
    reports:[],
    optionService: [],
    optionSubServices: [],
      Mainservice: '',
      notes: '',
      proposal_service_id: '',
      id: '',
      rows: [],
      columns: [
      {
          label: " اسم الخدمة",
          field: "name",
        },
        
        {
          label: " الملاحظة",
          field: "note",
        }
        
      ],
    }
  },
  created() {
    this.get(),
    this.getServiceType();
    
    this.userData = JSON.parse(localStorage.getItem('userData'))
 //    console.log( this.userData)
 //    console.log( this.userData.roles[0].name)
    this.role= this.userData.roles[0].name
 //    console.log( "rolename" + this.role)
  },

  watch: {
    Mainservice: function() {
     this.optionSubServices = [];
 
        let url = `/api/v1/main_services/${this.Mainservice}/proposal_services`;
        this.$http.get(url).then((res) => {

          let subServices = res.data.data;
          subServices.forEach((el) => {
            this.optionSubServices.push({ label: el.name, value: el.id });
          });
        });
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    get() {
      const url = `api/v1/families/${this.$route.params.id}/offered-services/all `
      // console.log(this.$route.params.id);

      this.$http.get(url).then(res => {
    //    console.log("data lubna",res.data.data);
        this.familyservice = res.data.data.proposal_servises
        this.rows =  this.familyservice

        
      })
      // //console.log(this.personWeak);
    },
    getServiceType() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {

        let proposal_services = res.data.data.main_services;
        proposal_services.forEach((el) => {
          this.optionService.push({ label: el.name, value: el.id });
        });
      });
    },
     addservice() {
      
         this.id = this.$route.params.id;
          
            let data = null;
                
            data = {
                  proposal_service_id: this.proposal_service_id,
                  notes: this.notes,
                  family_id: this.id,
                  
                };
                
         if(this.role == "information_manager"){
                  this.$http
        .post('/api/v1/families/family_proposal_services', data)
        .then(response => {
          this.get()
         this.proposal_service_id = ""
                  this.notes = ""
                  this.Mainservice= ""
          // console.log(response);
          this.$swal({
            title: '',
            text: '  تم إضافة الخدمة  بنجاح       ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).catch(error => {
                this.$swal({
                  text: `${error.response.data.message}`,
                  icon: 'error',

                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          this.proposal_service_id= "",
        this.notes= "",
        this.Mainservice= "",
          requestAnimationFrame(() => {
            this.$refs.simpleRules.reset()
          })
        })
         }
         else{
            this.$http.post("/api/v1/pending_actions/accept-family-service", data).then((res) => {
   this.proposal_service_id= "",
        this.notes= "",
        this.Mainservice= "",
        
 this.$swal({
                  title: "",
                  text: "تمت إضافة الخدمة بانتظار موافقة مدير المعلومات",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                }).catch(error => {
                this.$swal({
                  text: `${error.response.data.message}`,
                  icon: 'error',

                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })

      });
         }
       


      
    },

  },
  };
 
  </script>
  
  <style scoped>
  label {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #0971b8 !important;
  }
  .align-left {
    left: 50px;
  }
  
  .but {
    top: 40px;
  }
  </style>
  